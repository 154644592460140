import { css } from '@emotion/react'
import { amountToNumber, amountToString, type Amount, type FinancialRate } from '@orus.eu/amount'
import { formatDdMmYyyy, type CalendarDate } from '@orus.eu/calendar-date'
import { type ProductAttributes } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { memo } from 'react'
import { colors } from '../../../../colors.js'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import { useDialogVisibility, useScreenType } from '../../../../hooks/index.js'
import { useTranslate } from '../../../../localization/language-context.js'
import { useSkin } from '../../../../skin/hooks.js'
import { Avatar, Text } from '../../../atoms/index.js'
import { Button } from '../../../button/index.js'
import { PersistentNotification } from '../../../callout-notification/index.js'
import { PricingDetailsDialog } from '../../quote-v3/index.js'
import { PdfDocumentDialog } from '../pdf-document-dialog/index.js'

export type ContractBannerV2Props = {
  monthlyPrice: Amount
  yearlyPrice: Amount
  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  yearlyCommitmentHasDiscount: boolean
  /** The Orus discount */
  discount: FinancialRate
  /** The first period price. E.g., when paying monthly, the first month might include terrorism tax */
  firstPeriodPrice?: Amount
  /** The yearly terrorism tax. If it equals 0, it won't be shown */
  yearlyTerrorismTaxPrice?: Amount
  historyTakeoverPrice: Amount | undefined
  partnerApplicationFee: Amount | undefined
  productsAttributes: ProductAttributes[]
  /**
   * Contract start date
   */
  startDate?: CalendarDate
  pdfBytes?: Uint8Array
  downloadablePdfBytes?: Uint8Array
  isPartner?: boolean
  onEditQuote?: () => void
  operatingZone: OperatingZone
}

export const ContractBannerV2 = memo<ContractBannerV2Props>(function ContractBannerV2(props) {
  const translate = useTranslate()
  const {
    downloadablePdfBytes,
    pdfBytes,
    yearlyTerrorismTaxPrice,
    startDate,
    historyTakeoverPrice,
    partnerApplicationFee,
    isPartner,
  } = props
  const {
    hide: hideContractDialog,
    show: showContractDialog,
    visible: isContractDialogVisible,
  } = useDialogVisibility('contract')
  const {
    hide: hideDetailDialog,
    show: showDetailDialog,
    visible: isDetailDialogVisible,
  } = useDialogVisibility('details')

  const price = props.paymentRecurrence === 'monthly' ? props.monthlyPrice : props.yearlyPrice
  const priceAmountText = amountToString(price, { addCurrency: true, displayDecimals: true })
  const priceText = `${priceAmountText} ${translate(`per_time_unit_${props.paymentRecurrence}`)}`
  const variant = useScreenType()
  const { productsAttributes } = props
  const productNames = productsAttributes.map(({ productName }) => productName)
  const skin = useSkin()
  return (
    <div>
      <div
        css={css`
          background-color: ${skin.contractBannerBackgroundColor ?? colors.blue[800]};
          border-radius: ${spacing[50]};
          color: ${colors.white};
          padding: ${variant === 'desktop' ? spacing[70] : spacing[60]};
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: ${spacing[60]};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: ${spacing[50]};
          `}
        >
          <Text color={colors.periwinkle.dark} variant="subtitle1">
            {translate(isPartner ? 'the_insurance_contract' : 'your_insurance_contract')}
          </Text>
          <Button
            avatar={<Avatar color={colors.white} icon="pen-regular" />}
            size="small"
            variant="text"
            onClick={showDetailDialog}
          />
        </div>

        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            gap: ${spacing[30]};
          `}
        >
          <ContractCardGridRow title={translate('subscription_funnel_checkout_coverage')} values={productNames} />
          <ContractCardGridRow title={translate('subscription_funnel_checkout_rate')} values={[priceText]} />
          {yearlyTerrorismTaxPrice && amountToNumber(yearlyTerrorismTaxPrice) > 0 ? (
            <ContractCardGridRow
              title="Taxe attentat"
              values={[
                amountToString(yearlyTerrorismTaxPrice, {
                  addCurrency: true,
                  displayDecimals: true,
                }),
              ]}
              paymentPrecisionLabel="Payée le 1er mois"
            />
          ) : (
            <></>
          )}
          {historyTakeoverPrice && amountToNumber(historyTakeoverPrice) > 0 ? (
            <ContractCardGridRow
              title="Reprise du passé"
              values={[
                amountToString(historyTakeoverPrice, {
                  addCurrency: true,
                  displayDecimals: true,
                }),
              ]}
              paymentPrecisionLabel="Payée le 1er mois"
            />
          ) : (
            <></>
          )}
          {partnerApplicationFee && amountToNumber(partnerApplicationFee) > 0 ? (
            <ContractCardGridRow
              title="Frais de dossier"
              values={[
                amountToString(partnerApplicationFee, {
                  addCurrency: true,
                  displayDecimals: true,
                }),
              ]}
              paymentPrecisionLabel="Payés le 1er mois"
            />
          ) : (
            <></>
          )}
          {startDate ? (
            <ContractCardGridRow
              title={translate('subscription_funnel_checkout_start_date')}
              values={[formatDdMmYyyy(startDate)]}
            />
          ) : (
            <></>
          )}
        </div>

        <div
          css={css`
            justify-content: left;
            text-align: ${variant === 'desktop' ? 'match-parent' : 'center'};
          `}
        >
          <Button
            variant="text"
            avatar={<Avatar color={colors.white} icon="file-lines-regular" />}
            avatarPosition="left"
            onClick={showContractDialog}
          >
            <Text
              variant="button"
              css={css`
                color: ${colors.white};
                text-decoration: underline;
              `}
            >
              {translate('subscription_funnel_checkout_view_contract')}
            </Text>
          </Button>
        </div>
      </div>
      {isContractDialogVisible ? (
        <PdfDocumentDialog
          fileName={`Orus - ${translate('filename_agreed_terms_draft')}.pdf`}
          onClose={hideContractDialog}
          downloadablePdfBytes={downloadablePdfBytes}
          pdfBytes={pdfBytes}
        />
      ) : (
        <></>
      )}
      {isDetailDialogVisible ? (
        <PricingDetailsDialog
          monthlyPrice={props.monthlyPrice}
          yearlyPrice={props.yearlyPrice}
          paymentRecurrence={props.paymentRecurrence}
          forbiddenMonthlyPayment={props.forbiddenMonthlyPayment}
          commitment={props.commitment}
          yearlyCommitmentHasDiscount={props.yearlyCommitmentHasDiscount}
          discount={props.discount}
          firstPeriodPrice={props.firstPeriodPrice}
          yearlyTerrorismTaxPrice={props.yearlyTerrorismTaxPrice}
          partnerApplicationPrice={props.partnerApplicationFee}
          productsAttributes={props.productsAttributes}
          onClose={hideDetailDialog}
          isPartner={isPartner}
          operatingZone={props.operatingZone}
          footer={
            <div
              css={css`
                margin-top: ${spacing[60]};
              `}
            >
              {props.onEditQuote && (
                <PersistentNotification variant="info">
                  {translate(
                    isPartner
                      ? 'subscription_funnel_edit_quote_explanation_partner'
                      : 'subscription_funnel_edit_quote_explanation',
                  )}
                </PersistentNotification>
              )}
              <div
                css={css`
                  margin-top: ${spacing[70]};
                  display: flex;
                  justify-content: end;
                  gap: ${spacing[40]};
                `}
              >
                <Button
                  size={variant === 'desktop' ? 'medium' : 'large'}
                  fullWidth={variant === 'desktop' ? false : true}
                  variant="secondary"
                  onClick={hideDetailDialog}
                >
                  {translate('close')}
                </Button>
                {props.onEditQuote ? (
                  <Button
                    size={variant === 'desktop' ? 'medium' : 'large'}
                    fullWidth={variant === 'desktop' ? false : true}
                    variant="primary"
                    onClick={props.onEditQuote}
                  >
                    {translate('subscription_funnel_edit_quote_cta')}
                  </Button>
                ) : undefined}
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}
    </div>
  )
})

const ContractCardGridRow = memo<{
  title: string
  values: string[]
  paymentPrecisionLabel?: string
}>(function ContractCardGridRow({ title, values, paymentPrecisionLabel }) {
  const screenType = useScreenType()
  return (
    <>
      <Text variant="body2" color={colors.periwinkle.dark}>
        {title}
      </Text>
      <div>
        {values.map((value) => (
          <div
            key={value}
            css={css`
              text-align: right;
            `}
          >
            <Text variant="body2Medium">
              {value}
              {screenType === 'desktop' && paymentPrecisionLabel ? ` (${paymentPrecisionLabel})` : ''}
            </Text>
            {screenType === 'mobile' && paymentPrecisionLabel ? (
              <Text variant="body2Medium">({paymentPrecisionLabel})</Text>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </>
  )
})
